<template>
  <div>
    <bb-application-contract-card
      v-bind="cardProps"
      v-on="events"
      :class="spacerClass"
    />
    <component
      :visible="modalComponent.isVisible"
      :is="modalComponent.name"
      @close="modalComponent.onClose"
      v-bind="modalComponent.props"
      v-if="modalComponent"
    />
  </div>
</template>

<script>
import FlowMixin from '../mixins/flowMixin'
import '@bigbank/interface-components/dist/svg/ui/chevron-right'
import '@bigbank/interface-components/dist/svg/client/sign'
import TransferDetails from './TransferDetails'
import { depositStates, depositButtons } from '../utils/depositStates'
import currencyDefinitions from '../utils/getCurrency'
import { DEMAND_DEPOSIT_DASHBOARD, getViewDepositTrackerAction } from '@/TrackingActions'
import dayjs from 'dayjs'
import { formatDate } from '@/plugins/dateFormatters'
import { formatInterest, formatMoneyWithCurrency } from '@/plugins/numformat'
import { DepositType, DepositRouteName } from '@deposits/const'
import { AccountRouteName } from '@account/const'
import { useRootStore } from '../../../store/root'
import { mapState } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'

export default {
  name: 'deposit-card',
  mixins: [FlowMixin],
  components: {
    TransferDetails
  },
  data () {
    return {
      spacerClass: 'm-b-15',
      showTransferDetailsModal: false,
      depositStates,
      buttonTypes: depositButtons
    }
  },
  mounted () {
    const trackState = (this.deposit.showProlongButton && this.state === depositStates.ACTIVE) ? depositStates.ACTIVE_PROLONG : this.state
    const depositId = (this.deposit && this.deposit.id) ? this.deposit.id.toString() : null

    if (depositId) {
      this.$tracker.action(getViewDepositTrackerAction(trackState, this.deposit.depositType), {}, { contractId: depositId })
    }
  },
  props: {
    deposit: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'isCompany', 'locale', 'isChannelSE']),
    ...mapState(useDepositStore, ['featureFlags']),
    ...mapState(useRootStore, {
      rootFeatureFlags: 'featureFlags'
    }),
    internalState () {
      if (this.isWaitingActivation) {
        return 'WAITING_ACTIVATION'
      }

      return this.state
    },
    translations () {
      return {
        buttons: {
          [this.buttonTypes.FUNDING_OPTIONS]: this.$gettext('Funding options'),
          [this.buttonTypes.TRANSFER_DETAILS]: this.$gettext('Transfer Details'),
          [this.buttonTypes.SIGN_CONTRACT]: this.$gettext('Sign contract'),
          [this.buttonTypes.PROLONG_CONTRACT]: this.$gettext('Prolong Contract'),
          [this.buttonTypes.REOPEN_CONTRACT]: this.$gettext('Reopen'),
          [this.buttonTypes.APPLY_AGAIN]: this.$gettext('Apply again'),
          [this.buttonTypes.UPLOAD_FILES]: this.$gettext('Money origin files'),
          contract_details: this.$gettext('Contract Details'),
          view_transactions: this.$gettext('View Transactions')
        },
        sectionLabels: {
          depositAmount: this.$gettext('DEPOSIT AMOUNT'),
          interestRate: this.$gettext('INTEREST RATE'),
          depositPeriod: this.$gettext('DEPOSIT PERIOD'),
          paymentInterestRate: this.$gettext('PAYMENT INTEREST DATE'),
          interestRateChange: this.$pgettext('guaranteed_interest_dashboard', 'INTEREST RATE CHANGE DATE'),
          payoutFrequency: this.$gettext('Payout frequency')
        },
        rejectedSubtitle: this.$gettext('Unfortunately we are not able to offer you a deposit account at this moment.'),
        waitingSigningSubtitle: this.$gettext('Your contract is ready to sign!'),
        waitingSigningDescription: this.$gettext('Please review your deposit conditions and proceed with signing.'),
        proceedingSubtitle: this.$gettext('We are processing your application.'),
        proceedingDescription: this.$gettext('Please stand by.'),
        waitingSecondaryTitle: this.$gettext('Waiting for activation'),
        states: {
          REJECTED: this.$pgettext('state', 'Rejected'),
          ACTIVE: this.$pgettext('state', 'Active'),
          CLOSED: this.$pgettext('state', 'Closed'),
          WAITING_FUNDS: this.$pgettext('state', 'Waiting funds'),
          WAITING_ACTIVATION: this.$pgettext('state', 'Waiting for activation'),
          WAITING_SIGNING: this.$pgettext('state', 'Waiting signing'),
          TERMINATED: this.$pgettext('state', 'Terminated'),
          FORWARDED: this.$pgettext('state', 'Forwarded'),
          PROCEEDING: this.$pgettext('state', 'Proceeding'),
          UNKNOWN: this.$pgettext('state', 'Unknown')
        },
        payoutFrequencies: {
          END: this.$pgettext('payment_frequency', 'At the end'),
          YEAR: this.$pgettext('payment_frequency', 'Every year'),
          MONTH: this.$pgettext('payment_frequency', 'Every month')
        },
        prolong: {
          YES: this.$pgettext('prolong', 'Active'),
          NO: this.$pgettext('prolong', 'No')
        },
        notices: {
          regular: {
            WAITING_FUNDS: this.$pgettext('DEMAND_DEPOSIT', 'Your savings deposit will be activated as soon as you make your first payment')
          },
          corporate: {
            WAITING_FUNDS: this.$pgettext('CORPORATE_DEMAND_DEPOSIT', 'Your savings deposit will be activated as soon as you make your first payment')
          }
        }
      }
    },
    cardProps () {
      if (this.isWaitingActivation) {
        return this.waitingActivationCardProps
      } else if (this.isWaitingFunds) {
        return this.waitingFundsCardProps
      } else if (this.state === this.depositStates.REJECTED && !this.isDemand) {
        return this.rejectedCardProps
      } else if (this.isWaitingMoneyOriginFiles && this.isDemand) {
        return this.cardPropsWithSection
      } else if (this.isWaitingMoneyOriginFiles) {
        return this.waitingMoneyOriginCardProps
      } else if (this.isBeingReviewed) {
        return this.proceedingCardProps
      } else if (this.state === this.depositStates.WAITING_SIGNING) {
        return this.waitingSigningCardProps
      } else {
        return this.cardPropsWithSection
      }
    },
    cardSections () {
      const sections = [
        {
          label: this.translations.sectionLabels.depositAmount,
          content: this.depositAmount,
          types: [DepositType.Demand, DepositType.Term],
          enabled: this.showAmount
        },
        {
          label: this.translations.sectionLabels.interestRate,
          strikeThroughContent: this.isDemandIncreasedInterestRate
            ? formatInterest(this.deposit.regularInterest, this.locale, true)
            : null,
          content: formatInterest(this.deposit.interest, this.locale, true),
          types: [DepositType.Demand, DepositType.Term],
          enabled: true
        },
        {
          label: this.translations.sectionLabels.depositPeriod,
          content: this.depositPeriodFormated,
          types: [DepositType.Term],
          enabled: true
        },
        this.isDemandIncreasedInterestRate
          ? {
              label: this.translations.sectionLabels.interestRateChange,
              content: formatDate(this.deposit.guaranteedInterestEndDate),
              types: [DepositType.Demand],
              enabled: !this.isAnyClosedState,
              highlighted: true
            }
          : {
              label: this.translations.sectionLabels.paymentInterestRate,
              content: formatDate(this.deposit.nextInterestPaymentDate),
              types: [DepositType.Demand],
              enabled: !this.isAnyClosedState
            },
        {
          label: this.translations.sectionLabels.payoutFrequency,
          content: this.translations.payoutFrequencies[this.deposit.paymentFrequencyCode],
          types: [DepositType.Demand],
          enabled: this.isAnyClosedState
        }
      ]
        .filter(item => item.types.includes(this.deposit.depositType))
        .filter(item => item.enabled)
        .map(({ label, content, strikeThroughContent, highlighted }) => ({ label, content, strikeThroughContent, highlighted }))

      return sections.length ? sections : undefined
    },
    waitingMoneyOriginCardProps () {
      return {
        icon: this.headerIcon,
        amount: (this.showAmount && this.depositAmount) || undefined,
        title: this.deposit.contractNumber,
        subtitle: this.translations.proceedingSubtitle,
        description: this.translations.proceedingDescription,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton()
      }
    },
    waitingFundsCardProps () {
      const showTransferDetails = this.isMobile && !this.showApplyAgainButton && !this.showFundingOptionsButton

      const props = {
        amount: (this.showAmount && this.depositAmount) || undefined,
        title: this.deposit.contractNumber,
        subtitle: this.noticeDetails.title,
        description: this.noticeDetails.description,
        detailsList: showTransferDetails ? this.transferDetailsList : undefined,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton()
      }

      if (this.isDemand) {
        this.modifyDemandDepoProps(props)
      }

      return props
    },
    waitingActivationCardProps () {
      return {
        type: 'depo',
        status: 'inactive',
        title: this.deposit.contractNumber,
        secondaryTitle: this.formattedState,
        sections: this.cardSections,
        secondaryButton: this.getSecondaryButton()
      }
    },
    rejectedCardProps () {
      return {
        icon: this.headerIcon,
        amount: this.depositAmount,
        title: this.deposit.contractNumber,
        subtitle: this.translations.rejectedSubtitle,
        secondaryButton: this.getSecondaryButton()
      }
    },
    proceedingCardProps () {
      return {
        icon: this.headerIcon,
        amount: (this.showAmount && this.depositAmount) || undefined,
        title: this.deposit.contractNumber,
        subtitle: this.translations.proceedingSubtitle,
        description: this.translations.proceedingDescription,
        secondaryButton: this.getSecondaryButton()
      }
    },
    waitingSigningCardProps () {
      const props = {
        icon: this.headerIcon,
        amount: (this.showAmount && this.depositAmount) || undefined,
        title: this.deposit.contractNumber,
        subtitle: this.translations.waitingSigningSubtitle,
        description: this.translations.waitingSigningDescription,
        detailsList: this.isMobile ? this.signingDetailsList : undefined,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton()
      }

      if (this.showStatus) {
        props.depoStatusText = this.statusLabel + ' ' + this.status
      }

      if (this.isDemand) {
        this.modifyDemandDepoProps(props)
        delete props.iban
      }

      return props
    },
    cardPropsWithSection () {
      const props = {
        type: 'depo',
        title: this.deposit.contractNumber,
        secondaryTitle: this.statusLabel,
        timeRemaining: this.status,
        progress: { value: this.progressValue, max: this.progressMax },
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton(),
        sections: this.cardSections,
        depoStatusText: ' '
      }

      if (this.showState || (this.isDemand && this.isAnyClosedState)) {
        props.status = 'paidOut'
        props.secondaryTitle = this.formattedState
        props.depoStatusText = this.statusLabel + ' ' + this.status
      }

      if (this.isDemand) {
        delete props.secondaryTitle
        delete props.timeRemaining
        delete props.progress
        this.modifyDemandDepoProps(props)

        if (this.isAnyClosedState && this.rootFeatureFlags.enableSavingsDepositProductPageUpdate) {
          props.status = 'closed'
          props.secondaryTitle = this.statusLabel
          props.timeRemaining = formatDate(this.deposit.closedSortingDate)
          delete props.sections
          delete props.primaryButton
        }
      }

      return props
    },
    cardPrimaryButton () {
      if (this.showSigningButton) {
        return this.buttonTypes.SIGN_CONTRACT
      } else if (this.isWaitingMoneyOriginFiles) {
        return this.buttonTypes.UPLOAD_FILES
      } else if (this.showTransferDetailsButton) {
        return this.buttonTypes.TRANSFER_DETAILS
      } else if (this.showFundingOptionsButton) {
        return this.buttonTypes.FUNDING_OPTIONS
      } else if (this.showProlongationButton) {
        return this.buttonTypes.PROLONG_CONTRACT
      } else if (this.showReopenDemandDepositButton) {
        return this.buttonTypes.REOPEN_CONTRACT
      } else if (this.showApplyAgainButton) {
        return this.buttonTypes.APPLY_AGAIN
      } else {
        return null
      }
    },
    events () {
      return {
        primaryButtonClick: () => this.handleClick(this.cardPrimaryButton),
        secondaryButtonClick: (index) => this.handleClick(this.getSecondaryButton()[index].type)
      }
    },
    depositAmount () {
      let amount = this.deposit.amount

      if (this.state === this.depositStates.ACTIVE) {
        amount = this.deposit.availableBalance
      }

      return formatMoneyWithCurrency(amount, this.currency, this.locale)
    },
    state () {
      return this.deposit.state
    },
    isWaitingMoneyOriginFiles () {
      return this.deposit.isWaitingMoneyOriginFiles
    },
    isWaitingFunds () {
      return this.state === depositStates.WAITING_FUNDS
    },
    isWaitingActivation () {
      return this.state === depositStates.WAITING_FUNDS &&
        !this.isDemand &&
        this.transferDetails &&
        this.transferDetails.amount <= 0
    },
    isWaitingToBeSigned () {
      return this.state === depositStates.WAITING_SIGNING
    },
    isTerminated () {
      return this.state === depositStates.TERMINATED
    },
    isClosed () {
      return this.state === depositStates.CLOSED
    },
    isAnyClosedState () {
      return ['TERMINATED', 'CANCELED', 'REJECTED', 'CLOSED'].includes(this.state)
    },
    isProceeding () {
      return this.state === depositStates.PROCEEDING
    },
    headerIcon () {
      if (this.state === depositStates.WAITING_FUNDS && this.isProlongation) {
        return false
      }

      const iconName = {
        REJECTED: 'client-face-sad',
        WAITING_FUNDS: 'client-expired',
        PROCEEDING: 'client-expired',
        FORWARDED: 'client-expired',
        CLOSED: false,
        TERMINATED: false,
        WAITING_SIGNING: 'client-sign'
      }[this.state] || false

      return { name: iconName }
    },
    formattedState () {
      return this.translations.states[this.internalState]
    },
    isProlongation () {
      return this.deposit.isInProlongationState
    },
    isBeingReviewed () {
      return [depositStates.PROCEEDING, depositStates.FORWARDED].includes(this.state)
    },
    hasPayout () {
      return this.isTerminated || this.isClosed
    },
    showState () {
      return this.isTerminated || this.isClosed
    },
    showWaitingForActivation () {
      return this.state === depositStates.WAITING_FUNDS &&
        ((this.transferDetails && this.transferDetails.amount <= 0) || !this.daysToActivation)
    },
    showStatus () {
      return !this.showWaitingForActivation && this.state !== depositStates.PROCEEDING
    },
    showTransferDetailsButton () {
      return this.isWaitingFunds &&
        (this.isDemand || !this.featureFlags.enableTermDepositFundingOptions) &&
        !this.isMobile &&
        !this.showApplyAgainButton
    },
    showFundingOptionsButton () {
      return this.isWaitingFunds &&
        this.featureFlags.enableTermDepositFundingOptions &&
        !this.isDemand &&
        (this.transferDetails && this.transferDetails.amount > 0) &&
        !this.showApplyAgainButton
    },
    showApplyAgainButton () {
      return this.isWaitingFunds && this.daysToActivate < 0
    },
    showSigningButton () {
      return this.state === depositStates.WAITING_SIGNING && !this.featureFlags.disableDepositSigning
    },
    showAmount () {
      return !this.isDemand || !([
        depositStates.WAITING_SIGNING,
        depositStates.WAITING_FUNDS,
        depositStates.PROCEEDING,
        depositStates.FORWARDED
      ].includes(this.state))
    },
    showProlongationButton () {
      return !!this.deposit.showProlongButton
    },
    showReopenDemandDepositButton () {
      return this.isDemand && this.isAnyClosedState
    },
    currency () {
      return currencyDefinitions(this.deposit.currencyCode)
    },
    isDemand () {
      return this.deposit.depositType === DepositType.Demand
    },
    isDemandIncreasedInterestRate () {
      return this.isDemand && !!this.deposit.regularInterest && !!this.deposit.guaranteedInterestEndDate && this.deposit.interest > this.deposit.regularInterest
    },
    daysToActivation () {
      if (!this.deposit.hasSourceContract || !this.deposit.sourceContractEndDate) {
        return null
      }
      return dayjs(this.deposit.sourceContractEndDate).diff(dayjs(), 'day')
    },
    status () {
      let date = this.deposit.endDate

      if (this.isProlongation && this.deposit.hasSourceContract && this.deposit.sourceContractEndDate) {
        date = this.deposit.sourceContractEndDate
      }

      return formatDate(date)
    },
    statusLabel () {
      if (this.isDemand) {
        if (this.isAnyClosedState && this.rootFeatureFlags.enableSavingsDepositProductPageUpdate) {
          return this.$gettext('closed') + ': '
        } else if (this.isAnyClosedState) {
          return this.$gettext('End date') + ': '
        }

        return ''
      }

      if (this.hasPayout) {
        return this.$gettext('payout on')
      }

      if (this.isProlongation) {
        return this.$gettext('effective in') + ':'
      }

      return this.$gettext('contract ends') + ':'
    },
    progressValue () {
      const currentDate = dayjs()
      const activationDate = dayjs(this.deposit.activationDate)
      return this.hasPayout ? 1 : (Math.round(currentDate.diff(activationDate, 'day', true)) || 0)
    },
    progressMax () {
      const activationDate = dayjs(this.deposit.activationDate)
      const endDate = dayjs(this.deposit.endDate)
      return this.hasPayout ? 1 : (Math.round(endDate.diff(activationDate, 'day', true)) || 0)
    },
    daysToActivate () {
      if (!this.deposit || !this.deposit.lastActivationDate) {
        return null
      }

      const today = dayjs()
      const lastDayToActivate = dayjs(this.deposit.lastActivationDate)
      return lastDayToActivate.diff(today, 'day')
    },
    noticeType () {
      if (this.isDemand) {
        return 'WAITING_FUNDS'
      }

      if (this.showApplyAgainButton) {
        return 'PAST_DUE'
      } else if (this.deposit.hasSourceContract) {
        return 'TRANSFER_SOON_PROLONG'
      } else {
        return 'TRANSFER_SOON'
      }
    },
    noticeDetails () {
      const translationType = this.isCompany ? 'corporate' : 'regular'

      return {
        WAITING_FUNDS: {
          title: this.$pgettext('DEMAND_DEPOSIT', 'We are waiting for your funds!'),
          description: this.translations.notices[translationType].WAITING_FUNDS
        },
        TRANSFER_SOON: {
          title: this.$gettext('Deposit amount needs to be transferred within {days_left} days.').replace(/{days_left}/, this.daysToActivate),
          description: this.$gettext('In order to activate your deposit account please check the transfer details')
        },
        TRANSFER_SOON_PROLONG: {
          title: this.$pgettext('TERM_DEPOSIT_PROLONG', 'We are waiting for your funds!'),
          description: this.$pgettext('TERM_DEPOSIT_PROLONG', 'In order to activate your deposit account please check your account details.')
        },
        PAST_DUE: {
          title: this.$gettext('Deposit amount payment date has passed.'),
          description: this.$gettext('In order to open a deposit, please apply again.')
        }
      }[this.noticeType] || false
    },
    depositPeriodCode () {
      if (this.isDemand) {
        return ''
      }

      switch (this.deposit.periodCode.toLowerCase()) {
        case 'days':
          return this.$pgettext('period_code', 'd')
        case 'months':
          return this.$pgettext('period_code', 'mo')
        case 'years':
          return this.$pgettext('period_code', 'y')
        default:
          return ''
      }
    },
    depositPeriodFormated () {
      return this.deposit.period + ' ' + this.depositPeriodCode
    },
    prolongStatus () {
      return this.translations.prolong[this.deposit.isAutomatedProlong ? 'YES' : 'NO']
    },
    transferDetails () {
      return this.deposit.transferDetails
    },
    transferDetailsFiltered () {
      const details = { ...this.transferDetails || {} }
      if (!this.showAmount && details.amount) {
        delete details.amount
      }

      return details
    },
    transferDetailsList () {
      return [{
        label: this.$gettext('Sum to be transferred:'),
        content: formatMoneyWithCurrency(this.transferDetails.amount, this.currency, this.locale),
        hide: !this.showAmount
      }, {
        label: this.$gettext('Payee:'),
        content: this.transferDetails.receiverName
      }, {
        label: this.$gettext('IBAN:'),
        content: this.transferDetails.iban,
        hide: this.isChannelSE
      }, {
        label: this.$gettext('Account number:'),
        content: this.transferDetails.accountNumber,
        hide: !this.isChannelSE
      }, {
        label: this.$gettext('Ref number:'),
        content: this.transferDetails.referenceNumber
      }, {
        label: this.$gettext('BIC:'),
        content: this.transferDetails.bic,
        hide: this.isChannelSE
      }].filter(({ hide }) => !hide)
    },
    signingDetailsList () {
      return [{
        label: this.$gettext('Deposit amount'),
        content: this.depositAmount,
        hide: !this.showAmount
      }, {
        label: this.$gettext('Deposit period'),
        content: this.depositPeriodFormated,
        hide: this.isDemand
      }, {
        label: this.$gettext('Annual interest rate'),
        content: formatInterest(this.deposit.interest, this.locale, true)
      }, {
        label: this.$gettext('End date'),
        content: formatDate(this.deposit.endDate),
        hide: this.isDemand
      }, {
        label: this.$gettext('Payout frequency'),
        content: this.translations.payoutFrequencies[this.deposit.paymentFrequencyCode]
      }, {
        label: this.$gettext('Automatic prolongation'),
        content: this.prolongStatus,
        hide: this.isDemand || this.featureFlags.disableAutomaticProlongation
      }, {
        label: this.$pgettext('guaranteed_interest_details', 'Interest rate change date'),
        content: formatDate(this.deposit.guaranteedInterestEndDate),
        hide: !this.isDemandIncreasedInterestRate
      }, {
        label: this.$pgettext('guaranteed_interest_details', 'Regular interest rate'),
        content: formatInterest(this.deposit.regularInterest, this.locale, true),
        hide: !this.isDemandIncreasedInterestRate
      }].filter(({ hide }) => !hide)
    },
    modalComponent () {
      const modalComponents = [{
        name: 'transfer-details',
        props: {
          transferDetails: this.transferDetailsFiltered
        },
        onClose: () => {
          this.showTransferDetailsModal = !this.showTransferDetailsModal
        },
        isVisible: this.showTransferDetailsModal && this.transferDetails
      }]

      return modalComponents.find(component => component.isVisible)
    }
  },
  methods: {
    modifyDemandDepoProps (props) {
      delete props.icon
      props.copyButton = {
        successMsg: this.$gettext('Copied!'),
        errorMsg: this.$gettext('Copy failed!')
      }
      props.depoType = 'savings'
      props.iban = this.deposit.accountIban
      props.flavorAgnosticProgress = true
      props.separator = true
    },
    async showTransferDetails () {
      if (this.isDemand) {
        return this.$router.push('/my-deposits/demand/top-up')
      } else {
        this.showTransferDetailsModal = true
      }
    },
    showFundingOptions () {
      this.$router.push({
        name: DepositRouteName.FundingOptions,
        params: { depositId: this.deposit.id }
      })
    },
    getPrimaryButton () {
      if (this.cardPrimaryButton) {
        const button = {
          label: this.translations.buttons[this.cardPrimaryButton]
        }

        if ([this.buttonTypes.TRANSFER_DETAILS, this.buttonTypes.APPLY_AGAIN].includes(this.cardPrimaryButton)) {
          button.inverted = true
        }

        return button
      }

      return undefined
    },
    getSecondaryButton () {
      const buttons = [{
        label: this.translations.buttons.contract_details,
        type: this.buttonTypes.CONTRACT_DETAILS
      }]

      if (this.isDemand && !this.isWaitingFunds && !this.isBeingReviewed &&
        this.state !== this.depositStates.WAITING_SIGNING &&
        this.rootFeatureFlags.enableSavingsDepositProductPageUpdate
      ) {
        buttons.push({
          label: this.translations.buttons.view_transactions,
          type: this.buttonTypes.VIEW_TRANSACTIONS
        })
      }

      return buttons
    },
    handleClick (buttonType) {
      switch (buttonType) {
        case this.buttonTypes.UPLOAD_FILES:
          this.$router.push({ name: DepositRouteName.MoneyOriginDocuments, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.CONTRACT_DETAILS:
          this.$router.push({ name: DepositRouteName.Contract, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.PROLONG_CONTRACT:
          this.$router.push({ name: DepositRouteName.Prolongation, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.SIGN_CONTRACT:
          this.$router.push({ name: DepositRouteName.Signing, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.VIEW_TRANSACTIONS:
          this.$router.push({ name: AccountRouteName.MyTransactions, query: { accountID: this.deposit.currentAccountId } })
          break
        case this.buttonTypes.APPLY_AGAIN:
          this.startApplicationFlow(DepositType.Term)
          break
        case this.buttonTypes.REOPEN_CONTRACT:
          this.$tracker.action(DEMAND_DEPOSIT_DASHBOARD.DEMAND_CLICK_REOPEN_CONTRACT, {}, { contractId: this.deposit.id.toString() })
          this.startApplicationFlow(DepositType.Demand)
          break
        case this.buttonTypes.TRANSFER_DETAILS:
          this.showTransferDetails()
          break
        case this.buttonTypes.FUNDING_OPTIONS:
          this.showFundingOptions()
          break
      }
    }
  }
}
</script>
