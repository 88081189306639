<template>
  <signing-modal-wrapper
    :visible="visible"
    :showLoader="isLoading"
    @close="onClose"
    class="bankid-login__modal">
    <template v-if="!errorCode">
      <div class="bankid-login__modal__content">
        <div class="bankid-login__modal__header">
          {{ translations.header }}
        </div>
        <template v-if="qrData">
          <div class="m-t-20 m-b-40 bankid-login__modal__qr_info">
            <p>1. {{ translations.qrInstructions.first }}</p>
            <p>2. {{ translations.qrInstructions.second }}</p>
            <p>3. {{ translations.qrInstructions.third }}</p>
          </div>
          <qrcode-vue
            class="bankid-login__modal__spinner"
            :value="qrData"
            :size="qrSize"
            :margin="recommendedQRMargin"
            :level="recommendedQRErrorCorrectionLevel"
          />
        </template>
        <template v-else>
          <div class="bankid-login__modal__logo">
            <bb-logo :logo="bankIdLogo" alt="" fluid></bb-logo>
          </div>
          <div class="bankid-login__modal__info">
            {{ translations.info }}
          </div>
          <div class="bankid-login__modal__spinner">
            <bb-spinner></bb-spinner>
          </div>
        </template>
      </div>
      <div v-if="pending" slot="footer" class="bankid-login__modal__cancel">
        <a @click="cancel" href="#">{{ translations.cancel }}</a>
      </div>
    </template>
    <template v-else>
      <div class="bankid-login__modal__content">
        <div class="bankid-login__modal__header">
          {{ translations.errorHeader }}
        </div>
        <div class="bankid-login__modal__icon">
          <bb-icon fill="original" name="illustration-exclamation-mark"></bb-icon>
        </div>
        <div class="bankid-login__modal__error">
          {{errorMessage}}
        </div>
      </div>
      <bb-button
        @click="onSubmit"
        slot="footer"
        display="block">
        {{ translations.trySigningAgain }}
      </bb-button>
    </template>
  </signing-modal-wrapper>
</template>

<script>
import SigningModalWrapper from './SigningModalWrapper'
import bankIdLogo from './../../images/bankid.png'
import { SIGNING_VIEW } from '@/TrackingActions'
import autoOpenBankId from '@/plugins/autoOpenBankId'
import '@bigbank/interface-components/dist/svg/illustration/exclamation-mark'
import {
  SigningState,
  SigningStatus
} from '@bigbank/dc-common/clients/http/digital-signing-service/digital-signing-service.enums'
import { BankIdError } from '@bigbank/dc-common/clients/http/bank-id-service/bank-id-service.enums'
import QrcodeVue from 'qrcode.vue'

const MAX_AUTOMATIC_RETRY_ATTEMPTS = 3

export default {
  name: 'bankid-signing',
  components: { SigningModalWrapper, QrcodeVue },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      required: true,
      type: Function
    },
    completeSigning: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      automaticRetryCount: 0,
      bankIdLogo,
      statusPolling: null,
      errorCode: null,
      pending: false,
      isLoading: false,
      showErrorMessage: false,
      qrData: null,
      qrSize: 200, // same as login service
      recommendedQRErrorCorrectionLevel: 'L', // lowest is recommended for computer screens
      recommendedQRMargin: 4 // maybe helps something idk https://www.qreveal.com/blog/qr-code-margins
    }
  },
  mounted () {
    this.initSigning()
  },
  computed: {
    translations () {
      return {
        header: this.$pgettext('bank_id_signing', 'Start the app for BankID'),
        info: this.$pgettext('bank_id_signing', 'To sign, open your BankID application on your computer or mobile and sign the contract.'),
        cancel: this.$pgettext('bank_id_signing', 'Cancel'),
        errorHeader: this.$pgettext('bank_id_signing', 'Oops, something happened'),
        trySigningAgain: this.$pgettext('bank_id_signing', 'TRY SIGNING AGAIN'),
        qrInstructions: {
          first: this.$pgettext('bank_id_signing', 'Open the BankID app on your mobile'),
          second: this.$pgettext('bank_id_signing', 'Tap the QR symbol in the BankID app'),
          third: this.$pgettext('bank_id_signing', 'Point the camera at the QR code in this box')
        },
        errors: {
          [SigningState.Timeout]: this.$pgettext('error_bank_id_signing', 'The BankID app is not responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank.Try again.'),
          [SigningState.InvalidCertificate]: this.$pgettext('error_bank_id_signing', 'The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.'),
          [BankIdError.AlreadyInProgress]: this.$pgettext('error_bank_id', 'Your BankID session has expired, please try again.'),
          [SigningState.UserCancelled]: this.$pgettext('error_bank_id_signing', 'Signing process was cancelled. If this was not intentional, please try again.'),
          [SigningState.FailedUnknown]: this.$pgettext('error_bank_id', 'Your BankID session has expired, please try again.'),
          DEFAULT: this.$pgettext('error_bank_id_signing', 'Something appears to have gone wrong. Please try again later.')
        }
      }
    },
    errorMessage () {
      return this.translations.errors[this.errorCode] || this.translations.errors.DEFAULT
    }
  },
  methods: {
    cancel (e) {
      if (e && e.preventDefault) {
        e.preventDefault()
      }
      this.pending = false
      this.errorCode = null
      clearTimeout(this.statusPolling)
      this.statusPolling = null
      this.$emit('close')
    },
    async initSigning () {
      this.errorCode = null
      this.isLoading = true
      this.$tracker.action(SIGNING_VIEW.INIT_SIGNING)

      try {
        const result = await this.startSigning()
        this.isLoading = false
        this.pending = true
        this.pollStatus()
        if (result?.autoStartToken) {
          autoOpenBankId(result.autoStartToken)
        } else if (result.qrData) {
          this.qrData = result.qrData
        }
      } catch (err) {
        this.isLoading = false
        this.errorCode = err.err_code
        this.showErrorMessage = true
      }

      if (
        this.errorCode === BankIdError.AlreadyInProgress &&
        this.automaticRetryCount < MAX_AUTOMATIC_RETRY_ATTEMPTS
      ) {
        /*
          Customer tries signing
          Customer cancels
          Customer tries again
          CSI throws ALREADY_IN_PROGRESS
          Automatic retry
          CSI no longer throws error
          Customer can proceed
        */
        await this.initSigning()
        this.automaticRetryCount++
      }
    },
    pollStatus () {
      if (!this.pending) {
        return
      }
      this.statusPolling = setTimeout(() => {
        this.completeSigning()
          .then(res => {
            if (res.qrData) {
              this.qrData = res.qrData
            }

            this.pending = false
            switch (res.status) {
              case SigningStatus.CompletedOk:
                this.$emit('signingSuccessful')
                break
              case SigningStatus.Running:
                if (!this.statusPolling) {
                  return
                }
                this.pending = true
                this.pollStatus()
                break
              case SigningStatus.CompletedFailure:
                this.errorCode = res.state
                this.showErrorMessage = true
                break
              default:
                this.errorCode = 'UNKNOWN'
                this.showErrorMessage = true
            }
          })
          .catch(res => {
            this.pending = false
            this.errorCode = res.err_code
            this.showErrorMessage = true
            this.$emit('signingError', res)
          })
      }, 1000)
    },
    onClose (e) {
      this.cancel(e)
    },
    onSubmit (e) {
      if (this.errorCode !== SigningState.FailedUnknown) {
        this.cancel(e)

        return
      }
      this.automaticRetryCount = 0
      this.initSigning()
    }
  },
  beforeDestroy () {
    clearTimeout(this.statusPolling)
  }
}
</script>

<style lang="scss">
.bankid-login {
  &__modal {
    &__content {
      @media (min-width: $desktop-view-breaking-point) {
        min-height: 380px;
      }
    }

    &__header {
      text-align: center;
      color: $navy;
      font-family: $gotham-medium;
      font-size: $h4-size;
    }

    &__info {
      text-align: center;
      color: $gray-50;
      font-family: $gotham-medium;
      font-size: $font-size-small;
      margin-bottom: 42px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-bottom: 42px;
      }
    }

    &__qr_info {
      text-align: center;
      color: $gray-90;
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }

    &__error {
      text-align: center;
      color: $red;
      font-family: $gotham-medium;
      font-size: $font-size-small;
      margin-top: 35px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 46px;
      }
    }

    &__spinner {
      text-align: center;

      > div {
        margin: auto;
      }
    }

    &__cancel {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;

      a {
        font-size: $font-size-smallest;
        line-height: $font-size-smallest;
        color: $blue;
      }
    }

    &__logo {
      text-align: center;
      height: 52px;
      margin-top: 41px;
      margin-bottom: 41px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 35px;
      }
    }

    &__icon {
      margin: auto;
      margin-top: 59px;
      height: 40px;
      width: 40px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 43px;
      }
    }
  }
}
</style>
