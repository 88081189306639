<template>
  <bb-modal
    :visible="visible"
    flavor="card"
    publicSize="m"
    @close="onClose"
    fullMobile
    >
    <template #heading>{{errorTitle}}</template>
    <div v-if="showAlertIcon" class="signing-error__alert-icon">
      <bb-icon size="fluid" fill="original" name="illustration-exclamation-mark"></bb-icon>
    </div>
    <div class="signing-error__error-message">
      <text-with-links
        v-if="isInactiveCertificateError"
        :text="errorMessage" />
      <span v-else>{{errorMessage}}</span>
    </div>
    <div v-if="button" class="signing-error__button custom-hidden-desktop" slot="footer">
      <bb-button v-bind="buttonProps" @click="onClose" display="block" size="lg" class="m-s-a" corner="sharp" data-testid="signing-error-mobile-close-button"/>
    </div>
    <div v-if="button" class="signing-error__button my-hidden-mobile" slot="footer">
      <bb-button v-bind="buttonProps" @click="onClose" data-testid="signing-error-desktop-close-button"/>
    </div>
  </bb-modal>
</template>
<script>
import '@bigbank/interface-components/dist/svg/illustration/exclamation-mark'
import { SIGNING_METHODS } from '@bigbank/dc-common/enums/loginAndSigningMethods'
import TextWithLinks from '@/components/TextWithLinks.vue'
import { ErrorCode, SigningState } from '@bigbank/dc-common/clients/http/digital-signing-service/digital-signing-service.enums'
import { BankIdError } from '@bigbank/dc-common/clients/http/bank-id-service/bank-id-service.enums'

export default {
  name: 'signing-error-messages',
  components: { TextWithLinks },
  props: {
    code: {
      default: null,
      required: false
    },
    error: {
      default: null,
      required: false
    },
    type: {
      default: null,
      required: false
    },
    data: {
      default: null,
      required: false
    },
    titleOverride: {
      type: String,
      default: null,
      required: false
    },
    showAlertIcon: {
      type: Boolean,
      default: true
    },
    button: {
      type: String,
      default: 'close'
    },
    visible: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        [SIGNING_METHODS.BANK_ID]: {
          // https://stash.big.local/projects/DA/repos/digital-signing/browse/application/src/main/java/bigbank/authentication/dss/service/BankIdService.java
          [SigningState.UserCancelled]: this.$pgettext(
            'error_bank_id_signing',
            'Signing process was cancelled. If this was not intentional, please try again.'
          ),
          [SigningState.Timeout]: this.$pgettext(
            'error_bank_id_signing',
            'The BankID app is not responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank.Try again.'
          ),
          [SigningState.InvalidCertificate]: this.$pgettext(
            'error_bank_id_signing',
            'The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.'
          ),
          [BankIdError.AlreadyInProgress]: this.$pgettext(
            'error_bank_id_signing',
            'Previous sigining process is still ongoing. Please cancel the previous one or wait some time and try again.'
          )
        },
        [SIGNING_METHODS.MOBILE_ID]: {
          BB_API_5101: this.$pgettext('mobile_id_errors', 'Incorrect input parameters.'),
          BB_API_5300: this.$pgettext('mobile_id_errors', 'General error related to user\'s mobile phone.'),
          BB_API_5301: this.$pgettext('mobile_id_errors', 'Not a Mobile-ID user.'),
          SK_101: this.$pgettext('mobile_id_errors', 'Incorrect input parameters.'),
          SK_300: this.$pgettext('mobile_id_errors', 'General error related to user\'s mobile phone.'),
          SK_301: this.$pgettext('mobile_id_errors', 'Not a Mobile-ID user.'),
          SK_SIGN_EXPIRED_TRANSACTION: this.$pgettext('mobile_id_errors', 'Authentication timed out.'),
          SK_SIGN_USER_CANCEL: this.$pgettext('mobile_id_errors', 'The user cancelled the authentication.'),
          SK_SIGN_MID_NOT_READY: this.$pgettext('mobile_id_errors', 'The Mobile-ID on the SIM is not yet ready for the operations.'),
          SK_SIGN_PHONE_ABSENT: this.$pgettext('mobile_id_errors', 'The phone is switched off or out of coverage.'),
          SK_SIGN_SIM_ERROR: this.$pgettext('mobile_id_errors', 'SIM application error.')
        },
        [SIGNING_METHODS.SMART_ID]: {
          SIGNATURE_SMART_ID_RESULT_USER_REFUSED: this.$pgettext('smart_id_errors', 'User refused authentication'),
          SK_SIGN_EXPIRED_TRANSACTION: this.$pgettext('smart_id_errors', 'Authentication expired'),
          SMART_ID_API_0: this.$pgettext('smart_id_errors', 'ID not found')
        },
        [SIGNING_METHODS.ID_CARD]: {
          PIN_BLOCKED: this.$pgettext('id_card_errors', 'User PIN 2 is blocked'),
          PROVIDER_ERROR: this.$pgettext('id_card_errors', 'Unknown error on partner side'),
          INVALID_AUTHENTICATION: this.$pgettext('id_card_errors', 'Authentication was marked as invalid by service provider'),
          SIGNER_CERTIFICATE_REVOKED: this.$pgettext('id_card_errors', "Signer's certificate status is revoked"),
          SIGNER_CERTIFICATE_UNKNOWN: this.$pgettext('id_card_errors', "Signer's certificate is unknown"),
          INTERNAL_ERROR: this.$pgettext('id_card_errors', 'Unhandled error occurred during process'),
          INVALID_CERTIFICATE: this.$pgettext(
            'id_card_errors',
            'Customer does not have select method account or account level is not sufficient to perform signing'
          ),
          END_PROVIDER_MISCONFIGURATION: this.$pgettext(
            'id_card_errors',
            'There is an configuration error on customer account. Customer should login to self service portal or contact support'
          ),
          ERR_WEBEID_EXTENSION_UNAVAILABLE: this.$pgettext('id_card_errors', 'Web eID extension is not installed or is disabled. Please install or enable it and try again. If you have installed it, please restart your browser. If the problem persists, please contact support.')
        },
        [SIGNING_METHODS.EPARAKSTS]: {
          INACTIVE_CERTIFICATE: this.$pgettext('eparaksts_errors', 'Certificate is expired or is not yet active. How to create an eSignature password can be found [here](https://www.eparaksts.lv/lv/palidziba/buj/eParaksts_mobile_lietosana/Ka_atjaunot_eParaksts_paroli)')
        },
        common: {
          INVALID_PHONE_EE: this.$pgettext('common_errors',
            'Your main mobile number is not a valid Estonian Phone Number.\n' +
              'Please add a valid Estonian phone number to your account contact data'
          ),
          INVALID_ID_CODE: this.$pgettext('common_errors',
            'Your Personal Identity Code not valid.\n' +
              'Please add a valid Estonian ID to your account'
          ),
          TIMED_OUT: this.$pgettext('common_errors', 'Signature timed out'),
          UNKNOWN_SIGN_METHOD: this.$pgettext('common_errors',
            'You cannot sign documents with chosen login method, please log out and' +
            ' log in with a method that can be used for signing documents'
          ),
          EMU_SIGN_METHOD: this.$pgettext('common_errors',
            'You cannot sign documents with EMU login method. ' +
            'If you are a developer please override the signing method as specified in Readme.md'
          ),
          DEFAULT: this.$pgettext('common_errors', 'Something appears to have gone wrong. Please try again later.')
        }
      }
    },
    errorTitle () {
      return this.titleOverride || this.$pgettext('error_bank_id_signing', 'Error')
    },
    errorMessage () {
      if (!this.code) {
        return ''
      }

      const captureMessage = `Signing error. code: ${this.code}`

      if (window.APM_CONFIG) {
        this.$apm.captureError(this.error ?? new Error(captureMessage), {
          message: captureMessage
        })
      } else {
        console.error(captureMessage)
      }

      const error = this.translations[this.type]?.[this.code] ??
        this.translations.common[this.code] ??
        this.translations.common.default

      if (this.data) { return this.$gettextInterpolate(error, { errorData: this.data }) } else { return error }
    },
    isInactiveCertificateError () {
      return this.type === SIGNING_METHODS.EPARAKSTS && this.code === ErrorCode.InactiveCertificate
    },
    buttonProps () {
      const props = {
        display: 'block'
      }

      switch (this.button) {
        case 'logout':
          props.label = this.$gettext('Logout')
          props.to = '/logout?redirectToLogin'
          break
        case 'close':
        default:
          props.label = this.$gettext('Close')
          break
      }

      return props
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .signing-error {
    &__alert-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 160px;
      width: auto;
    }

    &__error-message {
      margin-top: 25px;
      color: $gray;
      text-align: center;
    }
  }
</style>
