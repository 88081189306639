module.exports = {
  STATELESS_COUNTRY_CODE: 'XA', // TODO: When you will change dc-common add this to the constants,
  LOAN_PREMATURE_FULLFILLMENT_MIN_DAYS: 1,
  LOAN_PREMATURE_FULLFILLMENT_MAX_DAYS: 30,
  ROUTER_PATHS: Object.freeze({
    CONFIRM_DATA: '/account/confirm'
  }),
  VERIFICATION_METHODS: {
    SMART_ID: {
      STATUS: {
        COMPLETED_OK: 'COMPLETED_OK',
        RUNNING: 'RUNNING'
      },
      POLL_DELAY_MS: 3000
    },
    EPARAKSTS: {
      STATUS: {
        COMPLETED_OK: 'COMPLETED_OK',
        RUNNING: 'RUNNING'
      },
      POLL_DELAY_MS: 1000
    }
  },
  CREDIT_CARD: Object.freeze({
    TYPE: {
      PHYSICAL_AND_VIRTUAL: 'PHYSICAL_AND_VIRTUAL',
      VIRTUAL: 'VIRTUAL'
    },
    SECRET_TYPE: Object.freeze({
      PIN: 'pin',
      PAN_AND_CVV: 'pan_and_cvv'
    }),
    SECRET_DISPLAY_TIME_MS: 90000,
    SECRETS_MODAL_STEP: Object.freeze({
      IDENTITY_CONFIRMATION: 0,
      SHOWING_PIN_WARNING: 1,
      SHOWING_PIN: 2,
      SHOWING_VIRTUAL_CARD_DETAILS_WARNING: 3,
      SHOWING_PAN_AND_CVV: 4
    }),
    ORDER_MODAL_STEP: {
      INFO: 0,
      SIGN_CONTRACT_AND_SHOW_SECRETS: 1
    },
    SECRETS_MODAL_SIGNING_WAY: Object.freeze({
      DEFAULT: 0,
      THROUGH_ACTIVATION: 1,
      THROUGH_ORDERING_VIRTUAL_CARD: 2
    }),
    SETTINGS_BUTTON_TYPE: Object.freeze({
      SHOW_PIN: 0,
      SHOW_DETAILS: 1,
      MANAGE_CARD: 2,
      BLOCK_OR_UNBLOCK: 3,
      VIEW_CARD_TRANSACTIONS: 4
    }),
    CVV_POSSIBLE_LENGTHS: [3, 4],
    TRANSACTIONS_PER_PAGE: 16
  }),
  FILE_UPLOADING: {
    // If file uploading happens too quick, delay it
    BUTTON_LOADING_ANTI_BLINKING_DELAY_MS: 750
  },
  MY_TRANSACTIONS: Object.freeze({
    TRANSACTIONS_PER_PAGE: 16,
    VISIBLE_TRANSACTIONS_PER_CLICK: 100
  }),
  INACTIVITY_NOTIFICATION_TIMER_DURATION_MS: 60000,
  DEPOSITS: Object.freeze({
    MONTHS_BEFORE_WITHDRAW: 2
  })
}
