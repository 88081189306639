import dayjs from 'dayjs'

export function transformDataIntoStatementOfFeesRequestBody (filters, printoutTypeCode) {
  return {
    periodStartDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
    periodEndDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
    accountId: filters.accountId,
    printoutTypeCode
  }
}
