import { featureRouteGuard, transactionalRouteGuard } from '@/plugins/routeGuards'
import { translationPicker } from '@/plugins/translations'
import TermDepositsView from './TermDepositsView.vue'
import UpdateMoneyOriginDocuments from '../../deposits/components/MoneyOrigin/UpdateMoneyOriginDocuments.vue'
import DemandDepositsView from './DemandDepositsView.vue'
import NewApplicationFlow from './NewApplicationFlow.vue'
import ContractDetailsView from './ContractDetailsView.vue'
import SigningView from './SigningView.vue'
import ProlongationFlow from './ProlongationFlow.vue'
import FundingOptions from './term/FundingOptions.vue'
import TopUp from './demand/TopUp.vue'
import Withdraw from './demand/Withdraw.vue'
import Statement from './demand/Statement.vue'
import { DepositRouteName, DepositType } from '@deposits/const'
import { api } from '../api'
import { useRootStore } from '@/store/root'
import OutpaymentCancellationModal
  from '@/modules/deposits/components/OutpaymentCancellation/OutpaymentCancellationModal.vue'
import ConfirmDataLanding from './ConfirmDataLanding.vue'

const analytics = {
  product: 'deposit'
}

export default [
  {
    path: 'new-deposit/:depositType?',
    name: DepositRouteName.New,
    component: NewApplicationFlow,
    beforeEnter: (to, from, next) => {
      const rootStore = useRootStore()
      if (rootStore.isOnboardingRequired) {
        const url = to.params.depositType === DepositType.Demand
          ? (rootStore.channelConfig.applyDepositUrl.SAVINGS_DEPOSIT ?? rootStore.channelConfig.applyDepositUrl.TERM_DEPOSIT)
          : rootStore.channelConfig.applyDepositUrl.TERM_DEPOSIT
        document.location = translationPicker(url, rootStore.language)
        return
      }
      return transactionalRouteGuard()(to, from, next)
    },
    meta: {
      analytics
    }
  },
  {
    name: DepositRouteName.Contract,
    path: ':contractId/details',
    component: ContractDetailsView,
    meta: {
      analytics
    }
  },
  {
    name: DepositRouteName.Signing,
    path: ':contractId/sign',
    component: SigningView,
    beforeEnter: transactionalRouteGuard(),
    meta: {
      analytics
    }
  },
  {
    name: DepositRouteName.Prolongation,
    path: ':contractId/prolong',
    component: ProlongationFlow,
    beforeEnter: transactionalRouteGuard(),
    meta: {
      analytics
    }
  },
  {
    name: DepositRouteName.MoneyOriginDocuments,
    path: ':contractId/upload-money-origin-files',
    component: UpdateMoneyOriginDocuments,
    beforeEnter: transactionalRouteGuard(),
    meta: {
      analytics
    }
  },
  {
    name: DepositRouteName.Deposits,
    path: ':status(closed)?',
    beforeEnter: async (to, from, next) => {
      const { redirectToRoute, pendingApplication } = await api.getDepositsCount()

      switch (redirectToRoute) {
        case DepositRouteName.New:
          next({
            name: DepositRouteName.New,
            params: {
              depositType: pendingApplication.depositType
            },
            query: {
              amount: pendingApplication.amount,
              period: pendingApplication.period || undefined,
              term: pendingApplication.term || undefined
            }
          })
          break
        case DepositRouteName.DemandDeposits:
          next({ name: DepositRouteName.DemandDeposits })
          break
        default:
          next({ name: DepositRouteName.TermDeposits })
      }
    }
  },
  {
    name: DepositRouteName.TermDeposits,
    path: 'term/:status(closed|transactions)?',
    meta: {
      pinNavbar: true,
      analytics
    },
    component: TermDepositsView,
    children: [{
      name: DepositRouteName.FundingOptions,
      path: ':depositId/funding-options',
      beforeEnter: transactionalRouteGuard(),
      component: FundingOptions
    }]
  },
  {
    name: DepositRouteName.DemandDeposits,
    path: 'demand/:status(closed)?',
    meta: {
      pinNavbar: true,
      analytics
    },
    component: DemandDepositsView,
    beforeEnter: async (to, from, next) => {
      const rootStore = useRootStore()
      const { totalByType } = (await rootStore.getDepositsCount()) ?? {}

      if (rootStore.featureFlags.enableDemandDeposits || totalByType?.DEMAND > 0) {
        next()
      } else if (rootStore.featureFlags.enableTermDeposits) {
        next({ name: DepositRouteName.TermDeposits })
      } else {
        next({ name: from.name })
      }
    },
    children: [{
      name: DepositRouteName.DemandDepositsTopUp,
      path: 'top-up',
      component: TopUp
    }, {
      name: DepositRouteName.DemandDepositsWithdraw,
      path: 'withdraw',
      beforeEnter: transactionalRouteGuard(),
      component: Withdraw
    }, {
      name: DepositRouteName.DemandDepositsStatement,
      path: 'statement',
      component: Statement,
      beforeEnter: featureRouteGuard('canDownloadDepositAccountStatement', undefined, 'deposit')
    }, {
      name: DepositRouteName.DemandDepositsCancelOutpayment,
      path: 'cancel-outpayment',
      beforeEnter: transactionalRouteGuard(),
      component: OutpaymentCancellationModal
    }]
  },
  {
    path: 'confirm-data-landing',
    name: DepositRouteName.ConfirmDataLanding,
    component: ConfirmDataLanding,
    meta: {
      analytics
    }
  }
]
