<template>
  <div>
    <bb-overlay
      :visible="true"
      color="white"
      class="contract_details__wrapper"
      :centerVertically="false"
      :constrained="false"
      :fluid="true"
    >
      <bb-button
        class="close-btn"
        color="gray"
        display="circle"
        inverted
        icon="ui-arrow-left"
        @click="backButtonClick"
      />
      <div class="contract_details__content">
        <div class="contract_details__prolong">
          <h3 class="contract_details__title">{{translations.contractTitle}}: {{contractNumber}}</h3>
          <div v-if="hasActions" class="hidden-mobile">
            <div
              v-if="!deposit.showProlongButton && deposit.showDisabledProlongButton"
              @mouseover="showTooltipDesktop = true"
              @mouseleave="showTooltipDesktop = false"
            >
              <bb-button color="gray" ref="disabled_prolong_button">
                <translate>Prolong Contract</translate>
              </bb-button>
              <bb-tooltip
                v-if="showTooltipDesktop"
                bubble
                :reference="$refs.disabled_prolong_button"
                @hide="showTooltipDesktop = false"
              >
                <translate :translate-params="{date: deposit.firstPossibleProlongDate}">
                  Your contract can be manually prolonged starting from %{date}
                </translate>
              </bb-tooltip>
            </div>
            <div v-else-if="deposit.showProlongButton">
              <bb-button :to="`/my-deposits/${deposit.id}/prolong`">
                <translate>Prolong Contract</translate>
              </bb-button>
            </div>
            <div v-else-if="deposit.isCloseButtonVisible">
              <bb-button
                @click="onCloseContractButtonClicked"
                :disabled="!deposit.isCloseButtonClickable"
                inverted
                color="blue"
              >
                <translate>Close Contract</translate>
              </bb-button>
              <bb-tooltip v-if="!deposit.isCloseButtonClickable" class="m-l-5">
                <translate :translate-params="{ minAmount: deposit.withdrawalInfo.withdrawalMinAmount }">
                  To close the contract, available balance has to be less than %{minAmount}€
                </translate>
              </bb-tooltip>
            </div>
          </div>
        </div>
        <info-box v-if="showInfobox" class="m-t-20" :data="infoBoxData">
          <template #payoutAccountItem="{ value }">
            <div class="info-box-item">
              <div>{{ value }}</div>
              <div
                v-if="canEditIban"
                class="edit-container"
                @click="updateDetails('IBAN')"
              >
                <bb-icon fill="blue" name="ui-pencil" size="font"/>
              </div>
            </div>
          </template>
          <template
            v-if="!featureFlags.disableAutomaticProlongation"
            #prolongItem="{ value }"
          >
            <div class="info-box-item">
              <div>{{ value }}</div>
              <div
                v-if="canEditProlongation"
                class="edit-container"
                @click="updateDetails('PROLONG')"
              >
                <bb-icon fill="blue" :name="prolongIcon" size="font"/>
              </div>
            </div>
          </template>
        </info-box>
        <div
          v-if="hasActions"
          class="contract_details__prolong hidden-desktop m-t-20"
        >
          <template v-if="!deposit.showProlongButton && deposit.showDisabledProlongButton">
            <bb-button
              color="gray"
              display="block"
              @click="showTooltipMobile = !showTooltipMobile"
              ref="disabled_prolong_button_mobile"
            >
              <translate>Prolong Contract</translate>
            </bb-button>
            <bb-tooltip
              bubble
              v-if="showTooltipMobile"
              :reference="$refs.disabled_prolong_button_mobile"
              @hide="showTooltipDesktop = false"
            >
              <translate :translate-params="{date: deposit.firstPossibleProlongDate}">
                Your contract can be manually prolonged starting from %{date}
              </translate>
            </bb-tooltip>
          </template>
          <bb-button
            v-else-if="deposit.showProlongButton"
            display="block"
            :to="`/my-deposits/${deposit.id}/prolong`"
          >
            <translate>Prolong Contract</translate>
          </bb-button>
          <bb-button
            v-else-if="deposit.isCloseButtonVisible"
            :disabled="!deposit.isCloseButtonClickable"
            display="block"
            color="blue"
            inverted
            @click="onCloseContractButtonClicked"
          >
            <translate>Close Contract</translate>
          </bb-button>
          <bb-tooltip v-if="!deposit.isCloseButtonClickable" class="m-l-5">
            <translate :translate-params="{ minAmount: deposit.withdrawalInfo.withdrawalMinAmount }">
              To close the contract, available balance has to be %{minAmount}€ or less
            </translate>
          </bb-tooltip>
        </div>
        <div
          v-if="showTransferDetails && transferDetails"
          class="transfer-details m-b-40 m-t-35"
        >
          <h3 class="contract_details__title second">{{translations.transferDetails}}</h3>
          <info-box :data="transferDetailsData" />
        </div>
        <bb-banner
          v-if="showBannerValidateIban"
          class="contract_details__validate-iban-banner m-b-40"
          visible
        >
          {{$gettextInterpolate(translations.ibanChangeValidate, ibanValidationBannerValues)}}
        </bb-banner>
        <bb-banner
          v-if="showSigningStatus"
          class="m-t-25 m-b-40"
          permanent
          visible
        >
          <div class="signing-pending">
            <div>{{translations.allSigned}}</div>
            <div class="loader"><bb-spinner small/></div>
          </div>
        </bb-banner>
        <details-box class="m-t-25 m-b-35" :data="depositDetailsData"/>
        <div v-if="showSchedule" class="schedule-table m-t-25">
          <h3 class="contract_details__title second hidden-desktop">
            {{translations.scheduleDetails}}
          </h3>
          <bb-table
            :data="schedule"
            :columns="scheduleColumns"
            striped
            headUnderline
            indexColumn
            color="blue"
          >
            <template #statusColumn="{ value }">
              <div class="status">
                <span v-if="value === 'PAID'" class="paid">
                  <bb-icon name="ui-check-circle-outline" size="18"/>
                  {{translations.paid}}
                </span>
                <span v-else class="scheduled" >
                  <bb-icon name="ui-clock-outline" size="18"/>
                  {{translations.scheduled}}
                </span>
              </div>
            </template>
            <template #footer>
              <td></td>
              <td>{{translations.total}}</td>
              <td>{{totalSchedulePrincipal}}</td>
              <td>{{totalScheduleInterest}}</td>
              <td>{{totalScheduleAmount}}</td>
            </template>
          </bb-table>
        </div>
        <div class="contract_details__content__summary">
          <h3
            class="contract_details__title second"
            :class="{'hidden-mobile': !showInfobox}"
          >
            {{translations.summary}}
          </h3>
          <bb-list-group>
            <bb-list-item
              v-for="(row, k) in summaryData"
              :key="k"
              :extraCondensed="isMobile"
              :class="{'hidden-desktop': row.hideOnDesktop}"
              :title="row.label"
            >
              {{row.value}}
            </bb-list-item>
          </bb-list-group>
        </div>
        <div v-if="showDocumentsSection" class="documents">
          <h3 class="contract_details__title second">
            {{translations.documentsTitle}}
          </h3>
          <document-list
            :files="documents"
            :downloadHandler="download"
            :translations="translations"
          />
        </div>
        <div v-if="isLoading" class="contract_details__content__loader">
          <bb-spinner />
        </div>
      </div>
    </bb-overlay>
    <update-details
      :visible="showUpdateDetailsModal"
      :fieldType="selectedUpdateFieldType"
      :deposit="deposit"
      @close="onDetailsClosed()"
      @saved="onDetailsSaved()"
    />
    <close-contract
      v-if="showCloseContractModal"
      @close="showCloseContractModal = false"
      :deposit="deposit"
    />
  </div>
</template>

<script>
import { api } from '../api'
import { formatMoneyWithCurrency, formatInterest } from '@/plugins/numformat'
import { formatDate } from '@/plugins/dateFormatters'
import FlowMixin from '../mixins/flowMixin'
import InfoBox from './InfoBox'
import DetailsBox from './DetailsBox'
import UpdateDetails from './UpdateDetails'
import CloseContract from './CloseContract'
import { depositStates as states } from '../utils/depositStates'
import currencyDefinitions from '../utils/getCurrency'
import { DEMAND_DEPOSIT_DASHBOARD, TERM_DEPOSIT_DASHBOARD } from '@/TrackingActions'
import DocumentList from '@/components/DocumentList'

import '@bigbank/interface-components/dist/svg/ui/clock-outline'
import '@bigbank/interface-components/dist/svg/ui/check-circle-outline'
import '@bigbank/interface-components/dist/svg/ui/arrow-left'
import '@bigbank/interface-components/dist/svg/client/pdf'
import '@bigbank/interface-components/dist/svg/ui/download'
import '@bigbank/interface-components/dist/svg/ui/pencil'
import '@bigbank/interface-components/dist/svg/ui/eye'
import { downloadFile } from '@/plugins/utils'
import { DepositType } from '@deposits/const'
import { DepositRouteName } from '../const'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'
import { useRootStore } from '../../../store/root'
import { isNil } from 'lodash'

export default {
  name: 'contract-details',
  mixins: [FlowMixin],
  components: {
    InfoBox,
    DetailsBox,
    UpdateDetails,
    CloseContract,
    DocumentList
  },
  data () {
    return {
      accountValidation: {
        waitingAccountValidation: false,
        accountNumber: '',
        clearingNumber: '',
        servicingAccount: ''
      },
      showUpdateDetailsModal: false,
      showCloseContractModal: false,
      selectedUpdateFieldType: null,
      paymentSchedule: null,
      documents: null,
      accruedInterestValue: null,
      showTooltipDesktop: false,
      showTooltipMobile: false
    }
  },
  props: {
    deposit: {
      type: Object,
      required: true
    }
  },
  watch: {
    async visible (isVisible) {
      if (isVisible) await this.fetchData()
    },
    showSigningStatus (isPending, oldValue) {
      if (isPending) {
        this.pollStatus()
      }
      if (oldValue && !isPending) {
        this.$notify({ text: this.translations.contractInSignedStatus, duration: 10000 })
      }
    }
  },
  computed: {
    ...mapState(useDepositStore, ['featureFlags']),
    ...mapWritableState(useDepositStore, ['lastFlowData', 'lastFlow']),
    ...mapState(useRootStore, ['isMobile', 'previousRoute', 'locale', 'isChannelSE']),
    translations () {
      const privateTranslations = {
        name: this.$gettext('Account holder'),
        payoutFrequencies: {
          END: this.$pgettext('payment_frequency', 'At the end'),
          YEAR: this.$pgettext('payment_frequency', 'Every year'),
          MONTH: this.$pgettext('payment_frequency', 'Every month')
        },
        prolong: {
          YES: this.$pgettext('prolong', 'Active'),
          NO: this.$pgettext('prolong', 'No'),
          CHANGED_CONDITIONS: this.$pgettext('prolong', 'View conditions')
        },
        status: {
          ACTIVE: this.$pgettext('status', 'Active'),
          WAITING_FUNDS: this.$pgettext('status', 'Waiting funds'),
          WAITING_SIGNING: this.$pgettext('status', 'Waiting signing'),
          REJECTED: this.$pgettext('status', 'Rejected'),
          TERMINATED: this.$pgettext('status', 'Terminated'),
          CLOSED: this.$pgettext('status', 'Closed'),
          FORWARDED: this.$pgettext('status', 'Forwarded'),
          PROCEEDING: this.$pgettext('status', 'Proceeding')
        },
        fileNames: {
          AGREEMENT: this.$pgettext('file_name', 'Agreement'),
          AGREEMENT_SIGNED: this.$pgettext('file_name', 'SignedAgreement'),
          AGREEMENT_UNSIGNED: this.$pgettext('file_name', 'UnsignedAgreement'),
          NOTIFICATION_SIGNED: this.$pgettext('file_name', 'SignedNotification')
        },
        accruedInterest: this.$gettext('Earned interest'),
        contractTitle: this.$pgettext('contract_details_title', 'Contract'),
        documentsTitle: this.$pgettext('contract_details_title', 'Documents'),
        transferDetails: this.$gettext('Transfer Details'),
        scheduleDetails: this.$gettext('Schedule details'),
        summary: this.$gettext('Summary'),
        endOfContract: this.$gettext('End of contract'),
        estimatedEndOfContract: this.$gettext('Estimated end of contract'),
        activationDate: this.$gettext('Activation date'),
        nextPaymentDate: this.$pgettext('contract_details', 'Next payment date'),
        payoutFrequency: this.$gettext('Payout frequency'),
        payoutAccount: this.$gettext('Payout account'),
        automaticProlong: this.$gettext('Automatic prolongation'),
        iban: this.$gettext('IBAN'),
        bic: this.$gettext('BIC'),
        receiverName: this.$gettext('Payee'),
        referenceNumber: this.$gettext('Ref number'),
        depositAmount: this.$gettext('Deposit amount'),
        amountOfPeriods: this.$gettext('Amount of periods'),
        interestEarned: this.$gettext('Total interest earned'),
        interestAccrued: this.$pgettext('contract_details', 'Accrued interest'),
        interestPaidOut: this.$pgettext('contract_details', 'Total interest paid out'),
        interestRate: this.$gettext('Total interest rate'),
        paid: this.$gettext('Paid!'),
        scheduled: this.$gettext('Scheduled'),
        total: this.$gettext('Total'),
        contractStatus: this.$gettext('Contract status'),
        contractNumber: this.$gettext('Contract number'),
        signedOn: this.$gettext('Signed on'),
        clearingNumber: this.$gettext('Clearing number'),
        bankAccount: this.$gettext('Bank account'),
        ibanChangeValidate: this.$gettext('You have changed your %{ accountType }. In order for it to be validated, ' +
                'please transfer 0.01 %{ currency } to %{ servicingAccount } from %{ newAccount } account or' +
                ' contact customer service'),
        allSigned: this.$pgettext('signing_status', 'You have signed the contract successfully. Please wait until we finalyze the contract.'),
        contractInSignedStatus: this.$pgettext('signed_notification', 'Your contract has been signed and finalyzed!')
      }

      if (this.isCompany) {
        return {
          ...privateTranslations,
          interestAccrued: this.$pgettext('corporate_contract_details', 'Accrued interest'),
          interestPaidOut: this.$pgettext('corporate_contract_details', 'Total interest paid out')
        }
      }

      return privateTranslations
    },
    productType () {
      return this.deposit.depositType
    },
    contractNumber () {
      return this.deposit.contractNumber
    },
    status () {
      const text = this.translations.status[this.deposit.state]
      return text || '?'
    },
    endOfContract () {
      return formatDate(this.deposit.endDate)
    },
    startOfContract () {
      return formatDate(this.deposit.activationDate)
    },
    nextPaymentDate () {
      return formatDate(this.deposit.nextInterestPaymentDate)
    },
    payoutFrequency () {
      const text = this.translations.payoutFrequencies[this.deposit.paymentFrequencyCode]
      return text || '?'
    },
    payoutAccount () {
      return this.deposit.accountIban
    },
    prolongStatus () {
      return this.translations.prolong[this.deposit.automatedProlongCode]
    },
    prolongIcon () {
      switch (this.deposit.automatedProlongCode) {
        case 'CHANGED_CONDITIONS':
          return 'ui-eye'
        default:
          return 'ui-pencil'
      }
    },
    depositAmount () {
      if (this.isDemand && [states.WAITING_SIGNING, states.WAITING_FUNDS].includes(this.deposit.state)) {
        return '...'
      }

      let amount = this.deposit.amount

      if (this.isActive) {
        amount = this.deposit.availableBalance
      }

      return formatMoneyWithCurrency(amount, this.transferCurrency, this.locale)
    },
    accruedInterest () {
      return formatMoneyWithCurrency(this.accruedInterestValue, this.transferCurrency, this.locale)
    },
    interestRate () {
      return formatInterest(this.deposit.interest, this.locale) + ' %'
    },
    periodCode () {
      const periodCode = (this.deposit.periodCode || '').toLowerCase()

      switch (periodCode) {
        case 'days':
          return this.$pgettext('period_code', 'd')
        case 'months':
          return this.$pgettext('period_code', 'mo')
        default:
          return ''
      }
    },
    period () {
      return this.deposit.period + ' ' + this.periodCode
    },
    receiverName () {
      return this.transferDetails ? this.transferDetails.receiverName : '-'
    },
    iban () {
      return this.transferDetails ? this.transferDetails.iban : '-'
    },
    bic () {
      return this.transferDetails ? this.transferDetails.bic : '-'
    },
    reference () {
      return this.transferDetails ? this.transferDetails.referenceNumber : '-'
    },
    schedule () {
      if (!this.paymentSchedule) {
        return []
      }

      return this.paymentSchedule.schedule.map(item => ({
        status: item.status,
        paymentDate: formatDate(item.paymentDate),
        principalAmount: formatMoneyWithCurrency(item.principalAmount, this.transferCurrency, this.locale, { zeroAs: '-' }),
        interestAmount: formatMoneyWithCurrency(item.interestAmount, this.transferCurrency, this.locale)
      }))
    },
    scheduleColumns () {
      return [
        {
          key: 'paymentDate',
          label: this.$gettext('Payment date')
        },
        {
          key: 'principalAmount',
          label: this.$gettext('Principal amount')
        },
        {
          key: 'interestAmount',
          label: this.$gettext('Interest to be paid')
        },
        {
          key: 'status',
          label: this.$gettext('Status')
        }
      ]
    },
    totalSchedulePrincipal () {
      return formatMoneyWithCurrency(this.paymentSchedule.total.principalAmount, this.transferCurrency, this.locale, { zeroAs: '-' })
    },
    totalScheduleInterest () {
      return formatMoneyWithCurrency(this?.paymentSchedule?.total?.interestAmount, this.transferCurrency, this.locale, { zeroAs: '-' })
    },
    totalScheduleAmount () {
      const { principalAmount, interestAmount } = this.paymentSchedule.total
      return formatMoneyWithCurrency(principalAmount + interestAmount, this.transferCurrency, this.locale)
    },
    canEditProlongation () {
      return !this.featureFlags.disableAutomaticProlongation && [states.ACTIVE].includes(this.deposit.state) && !this.showSigningStatus
    },
    canEditIban () {
      return !this.featureFlags.disableTermDepositContractIbanChange && [states.ACTIVE].includes(this.deposit.state) && !this.showSigningStatus
    },
    isLoading () {
      return this.paymentSchedule === null || this.documents === null
    },
    showTransferDetails () {
      return [states.WAITING_FUNDS].includes(this.deposit.state)
    },
    transferDetails () {
      return this.deposit.transferDetails
    },
    showBannerValidateIban () {
      return this.accountValidation.waitingAccountValidation
    },
    ibanValidationBannerValues () {
      const { accountNumber, clearingNumber, servicingAccount } = this.accountValidation

      return {
        accountType: this.isChannelSE ? 'Account and Clearing Number' : 'IBAN',
        currency: this.transferCurrency,
        servicingAccount,
        newAccount: this.isChannelSE
          ? accountNumber + '-' + clearingNumber
          : accountNumber
      }
    },
    transferCurrency () {
      return currencyDefinitions(this.deposit.currencyCode)
    },
    endOfContractLabel () {
      if ([states.WAITING_SIGNING, states.WAITING_FUNDS, states.PROCEEDING].includes(this.deposit.state)) {
        return this.translations.estimatedEndOfContract
      } else {
        return this.translations.endOfContract
      }
    },
    infoBoxData () {
      return [{
        label: this.endOfContractLabel,
        value: this.endOfContract
      }, {
        label: this.translations.payoutFrequency,
        value: this.payoutFrequency
      }, {
        label: this.translations.payoutAccount,
        value: this.payoutAccount,
        key: 'payoutAccount',
        hide: this.isChannelSE
      }, {
        label: this.translations.automaticProlong,
        value: this.prolongStatus,
        key: 'prolong',
        hide: this.featureFlags.disableAutomaticProlongation
      }, {
        label: this.translations.clearingNumber,
        value: this.deposit.clearingNumber,
        hide: !this.isChannelSE
      }, {
        label: this.translations.bankAccount,
        value: this.deposit.principalAccount,
        key: 'payoutAccount',
        hide: !this.isChannelSE
      }]
    },
    depositDetailsData () {
      return [{
        label: this.translations.depositAmount,
        value: this.depositAmount,
        visible: true
      }, {
        label: this.translations.amountOfPeriods,
        value: this.period,
        visible: [DepositType.Term].includes(this.productType)
      }, {
        label: this.translations.accruedInterest,
        value: this.accruedInterest,
        visible: true
      }, {
        label: this.translations.interestRate,
        value: this.interestRate,
        visible: true
      }].filter(({ visible }) => visible)
    },
    transferDetailsData () {
      return [{
        label: this.translations.bic,
        value: this.bic
      }, {
        label: this.translations.receiverName,
        value: this.receiverName
      }, {
        label: this.translations.referenceNumber,
        value: this.reference
      }, {
        label: this.translations.iban,
        value: this.iban,
        hide: this.isChannelSE
      }]
    },
    summaryData () {
      return [{
        label: this.translations.depositAmount,
        value: this.depositAmount,
        hideOnDesktop: true,
        visible: true
      }, {
        label: this.translations.amountOfPeriods,
        value: this.period,
        hideOnDesktop: true,
        visible: [DepositType.Term].includes(this.productType)
      }, {
        label: this.translations.interestAccrued,
        value: this.accruedInterest,
        hideOnDesktop: true,
        visible: true
      }, {
        label: this.translations.interestEarned,
        value: this.totalScheduleInterest,
        hideOnDesktop: false,
        visible: [DepositType.Term].includes(this.productType)
      }, {
        label: this.translations.interestPaidOut,
        value: this.totalScheduleInterest,
        hideOnDesktop: false,
        visible: [DepositType.Demand].includes(this.productType)
      }, {
        label: this.translations.interestRate,
        value: this.interestRate,
        hideOnDesktop: true,
        visible: [DepositType.Demand].includes(this.productType)
      }, {
        label: this.translations.contractStatus,
        value: this.status,
        visible: true
      }, {
        label: this.translations.activationDate,
        value: this.startOfContract,
        visible: true
      }, {
        label: this.translations.nextPaymentDate,
        value: this.nextPaymentDate,
        visible: !!this.deposit.nextInterestPaymentDate
      }, {
        label: this.translations.payoutFrequency,
        value: this.payoutFrequency,
        visible: true
      }, {
        label: this.$pgettext('guaranteed_interest_details', 'Interest rate change date'),
        value: formatDate(this.deposit.guaranteedInterestEndDate),
        visible: this.isDemandIncreasedInterestRate
      }, {
        label: this.$pgettext('guaranteed_interest_details', 'Regular interest rate'),
        value: formatInterest(this.deposit.regularInterest, this.locale, true),
        visible: this.isDemandIncreasedInterestRate
      }].filter(({ visible }) => visible)
    },
    showSigningStatus () {
      return this.deposit.signingInfo && this.deposit.signingInfo.allSigned
    },
    hasActions () {
      return this.deposit.showProlongButton || this.deposit.showDisabledProlongButton || this.deposit.isCloseButtonVisible
    },
    showInfobox () {
      return [DepositType.Term].includes(this.productType)
    },
    showSchedule () {
      return [DepositType.Term].includes(this.productType) && this.schedule.length > 0
    },
    backButtonRoute () {
      const routes = {
        [DepositType.Demand]: { name: DepositRouteName.DemandDeposits },
        [DepositType.Term]: { name: DepositRouteName.TermDeposits },
        default: '/my-deposits'
      }

      return this.nullishPreviousRouteAfterSigning ?? routes[this.productType] ?? routes.default
    },
    nullishPreviousRouteAfterSigning () {
      return isNil(this.previousRoute.query.callback) ? this.previousRoute : null
    },
    isDemand () {
      return this.deposit
        ? this.productType === DepositType.Demand
        : this.flowData.agreementTypeCode === this.flowConstants.AGREEMENT_TYPE_CODES[DepositType.Demand]
    },
    isDemandIncreasedInterestRate () {
      return this.isDemand && !!this.deposit.regularInterest && !!this.deposit.guaranteedInterestEndDate
    },
    isActive () {
      return ['ACTIVE'].includes(this.deposit.state)
    },
    showDocumentsSection () {
      return this.documents && this.documents.length > 0 && !this.featureFlags.disableDepositDocuments
    }
  },
  methods: {
    ...mapActions(useRootStore, ['checkTransactionsAllowed']),
    ...mapActions(useDepositStore, [
      'fetchDeposits',
      'fetchContractDetails'
    ]),
    async fetchData () {
      if (!this.paymentSchedule) {
        this.paymentSchedule = await api.getSchedule(this.deposit.id)
      }
      const accountValidationResponse = await api.getValidIban(this.deposit.id)

      if (accountValidationResponse) {
        this.accountValidation = accountValidationResponse
      }

      this.accruedInterestValue = (await api.getAccruedInterest(this.deposit.id)).accruedInterest

      if (!this.showSigningStatus) {
        await this.fetchDocuments()
      }
    },
    async updateDetails (selectedUpdateFieldType) {
      this.lastFlow = 'contract-details'
      this.lastFlowData = {
        deposit: this.deposit,
        transferDetails: this.transferDetails
      }
      if (await this.transactionsAllowed()) {
        this.selectedUpdateFieldType = selectedUpdateFieldType
        this.showUpdateDetailsModal = true
      }
    },
    async onDetailsSaved () {
      await this.fetchDeposits(true)
      this.showUpdateDetailsModal = false
    },
    async fetchDocuments () {
      this.documents = (await api.getDocuments(this.deposit.id)).map(document => {
        return {
          dateTitle: document.signingDate ? this.translations.signedOn + ' ' + document.signingDate : '',
          title: this.documentFileName(document),
          ...document
        }
      })
    },
    onDetailsClosed () {
      this.showUpdateDetailsModal = false
    },
    async fetchDataDuringPolling () {
      await this.fetchContractDetails({ contractId: this.$route.params.contractId, showLoader: false })
      if (!this.showSigningStatus) {
        await this.fetchDocuments()
      }
    },
    pollStatus () {
      if (this.pollTimeout) {
        clearTimeout(this.pollTimeout)
      }
      if (!this.showSigningStatus) {
        return
      }

      this.pollTimeout = setTimeout(async () => {
        await this.fetchDataDuringPolling()
        this.pollStatus()
      }, 5000)
    },
    backButtonClick () {
      this.$router.push(this.backButtonRoute)
    },
    documentFileName (document) {
      const { fileType, typeCode, fileName } = document
      const translatedFileName = this.translations.fileNames[typeCode]

      return translatedFileName ? `${translatedFileName}.${fileType}` : fileName
    },
    toggleCloseContractModalVisibility (flag) {
      this.showCloseContractModal = flag
    },
    onCloseContractButtonClicked () {
      if (!this.checkTransactionsAllowed()) {
        return
      }

      this.toggleCloseContractModalVisibility(true)
    },
    async download (file) {
      await downloadFile(file.downloadUrl, file.fileName, {
        contentType: file.contentType
      })
    }
  },
  async mounted () {
    if (this.isDemand) {
      this.$tracker.action(
        DEMAND_DEPOSIT_DASHBOARD.DEMAND_DEPOSIT_VIEW_CONTRACT_DETAILS,
        {},
        { contractId: this.$route.params.contractId }
      )
    } else {
      this.$tracker.action(
        TERM_DEPOSIT_DASHBOARD.TERM_DEPOSIT_VIEW_CONTRACT_DETAILS,
        {},
        { contractId: this.$route.params.contractId }
      )
    }
    await this.fetchData()
    this.pollStatus()
  }
}
</script>

<style lang="scss">
  .bb-button.close-btn {
    position: absolute;
    top: 10px;
    left: 10px;

    @media (min-width: $desktop-view-breaking-point) {
      top: 17px;
    }

    @media (min-width: 900px) {
      left: 51px;
    }
  }

  .signing-pending {
    color: $gray;
    display: flex;

    .loader {
      padding: 0 20px;
      flex-direction: column;
    }
  }

  .contract_details {
    &__wrapper {
      &.bb-overlay--white {
        background-color: $white;

        .bb-overlay__content {
          margin: auto;
          width: 100%;
        }
      }

      .contract_details__prolong {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      .info-box {
        clear: both;

        .info-box-item {
          display: flex;
          justify-content: space-between;

          .edit-container {
            margin-left: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $blue;
            font-family: $gotham-medium;
            font-size: $default-font-size;
          }
        }
      }
    }

    &__validate-iban-banner {
      margin-top: 30px;
    }

    &__title {
      color: $navy;
      font-family: $max-pro-demi-bold;
      width: 100%;
      margin-bottom: 0;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 0;
        width: auto;
      }

      &.second {
        margin: 0;
        margin-bottom: 25px;

        @media (min-width: $desktop-view-breaking-point) {
          margin-bottom: 30px;
        }
      }
    }

    &__content {
      padding: 70px 15px 96px;
      max-width: 850px;
      margin: auto;

      &__summary {
        margin-top: 30px;
      }

      &__loader {
        margin-top: 15px;
        display: flex;
        justify-content: center;
      }

      .schedule-table {
        .contract_details__title {
          margin-bottom: -22px;
        }

        @media (min-width: $desktop-view-breaking-point) {
          margin-top: 40px;
        }

        tr {
          td,
          th {
            padding-left: 0.6rem;
            padding-right: 0.6rem;
            font-size: $font-size-small;
            font-weight: 500;
            color: $navy;
            text-align: right;
            white-space: nowrap;

            &.col-header {
              white-space: normal;
              text-transform: uppercase;
              font-family: $gotham-bold;
              font-size: $font-size-smallest;
              color: $gray;
              text-align: right;
              min-width: 7rem;
            }

            @media (min-width: $desktop-view-breaking-point) {
              font-size: $default-font-size;
            }

            &:nth-child(2) {
              text-align: left;
            }

            &:first-child {
              max-width: 2rem;
              padding-left: 0;
              padding-right: 20px;
              text-align: left;

              &::after {
                height: 0 !important;
              }
            }
          }

          &.tfoot {
            td {
              &:nth-child(2) {
                text-transform: uppercase;
              }

              background: none !important;
              font-family: $gotham-bold;

              &::after {
                height: 2px;
              }
            }
          }
        }

        .status {
          font-family: $gotham-bold;
          font-size: $font-size-small;

          .scheduled {
            color: $gray-70;
          }

          .paid {
            color: $mint;
          }

          svg {
            margin-right: 10px;
          }
        }
      }

      .documents {
        margin-top: 45px;
      }
    }
  }
</style>
