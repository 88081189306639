<template>
  <div>
    <bb-row class="m-b-20 m-b-sm-30">
      <bb-col>
        <div class="m-b-15 m-b-md-0 color-gray f-gotham-medium f-small">{{ computedTranslations.period }}</div>
        <bb-radio
          v-model="val.period"
          :options="filteringPeriods"
          :disabled="areFilterInputsDisabled"
          @change="() => onPeriodChange()"
          display="link"
          name="period-radio"
          class="hidden-max-md"
        />
        <bb-select
          v-model="val.period"
          :options="filteringPeriods"
          :disabled="areFilterInputsDisabled"
          :noempty="true"
          @change="() => onPeriodChange()"
          class="hidden-min-md p-t-0"
          name="period-select"
          framed
        />
      </bb-col>
    </bb-row>
    <bb-row v-if="isCreditCardDropdownShown || isContractDropdownShown" class="m-b-20 m-b-sm-30">
      <bb-col v-if="isContractDropdownShown" col="24" md="12" >
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ computedTranslations.contractNumber }}</div>
          <bb-select
            v-model="val.contractId"
            name="account-contract-id"
            :disabled="isLoading"
            framed
            :placeholder="computedTranslations.noContractSelected"
            class="p-0"
            :options="contractOptions"
          />
      </bb-col>
      <bb-col v-if="isCreditCardDropdownShown" col="24" md="12">
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ computedTranslations.cardNumber }}</div>
        <bb-select
          v-model="val.cardId"
          name="account-card-id"
          :disabled="isLoading"
          framed
          :placeholder="computedTranslations.noCardSelected"
          class="p-0"
          :options="cardOptions"
        />
      </bb-col>
    </bb-row>
    <bb-row class="m-b-20 m-b-sm-30">
      <bb-col>
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ computedTranslations.search }}</div>
        <bb-fieldset-repeater
          v-model="val.advancedSearchFilters"
          :rowLimit="maxFiltersCount + 1"
          :emptyRowModel="advancedFilterEmptyRowModel"
          :add-button-label="computedTranslations.addMore"
          :bleed="false"
          :disableAdding="isAddingNewFiltersDisabled || isStatementOfFeesCheckboxEnabled"
          condensed
          headerless
        >
          <template #fields="row">
            <bb-row gutter="20">
              <span class="hidden">
                  {{ row.model.filterFunction = row.model.filterFunction || transactionsSettings }}
              </span>
              <bb-col col="24" md="12" class="m-b-5 m-b-md-0">
                <bb-input
                  v-model="row.model.filterValue"
                  :name="`filterValue${row.id}`"
                  :disabled="areFilterInputsDisabled || isStatementOfFeesCheckboxEnabled"
                  :placeholder="computedTranslations.typeHereToSearch" framed class="p-0"/>
              </bb-col>
              <bb-col col="24" md="12" class="m-b-5 m-b-md-0">
                <bb-select
                  v-model="row.model.filterFunction"
                  :name="`filterFunction${row.id}`"
                  :disabled="isLoading"
                  :options="filteringAdvancedSearchOptions"
                  noempty
                  framed
                  class="p-0"
                />
              </bb-col>
            </bb-row>
          </template>
        </bb-fieldset-repeater>
      </bb-col>
    </bb-row>
    <bb-row class="m-b-10">
      <bb-col class="p-l-lg-5">
        <bb-checkbox
          v-for="(checkbox) in additionalOptionsCheckboxes"
          :name="checkbox.name"
          :key="checkbox.name"
          v-model="val.additionalOptionsCheckboxes[checkbox.name]"
          :disabled="checkbox.disabled || isStatementOfFeesCheckboxEnabled"
          size="small"
          class="d-inline-block"
        >{{ checkbox.label }}</bb-checkbox>
        <bb-checkbox
            v-if="isStatementOfFeesCheckboxVisible"
            :name="statementOfFeesCheckboxData.name"
            v-model="isStatementOfFeesCheckboxEnabled"
            :disabled="statementOfFeesCheckboxData.disabled"
            size="small"
            class="d-inline-block"
        >
          {{ statementOfFeesCheckboxData.label }}
          <bb-tooltip>
            {{ statementOfFeesCheckboxData.tooltip }}
          </bb-tooltip>
        </bb-checkbox>
      </bb-col>
    </bb-row>
  </div>
</template>

<script>
import { TransactionsPeriodFilter, TransactionAdvancedSearchOption } from '@bigbank/dc-common/enums/credit-card.enums'
import { capitalize } from 'lodash'
import { useRootStore } from '@/store/root'
import { getTranslation } from '@/plugins/translations'
import { BbFieldsetRepeater } from '@bigbank/interface-components'
import CardTypeTranslationMixin from '@credit-card/components/mixins/CardTypeTranslationMixin'
import { mapState, mapWritableState } from 'pinia'
import { useAccountStatementStore } from '@account/store/accountStatementStore'

export default {
  name: 'advanced-transaction-filter',
  components: {
    BbFieldsetRepeater
  },
  mixins: [
    CardTypeTranslationMixin
  ],
  props: {
    value: { // used with v-model
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      default: () => []
    },
    flavor: {
      type: String,
      required: true,
      validator: value => {
        return [
          'account',
          'credit-card'
        ].includes(value)
      }
    },
    areFilterInputsDisabled: {
      type: Boolean,
      default: false
    },
    maxFiltersCount: {
      type: Number,
      default: 5
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    transactionsSettings: {
      type: String
    },
    additionalOptionsCheckboxes: {
      type: Array,
      default: () => []
    },
    translations: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      val: this.value || {
        period: null,
        filters: [],
        advancedSearchFilters: [],
        cardId: null,
        contractId: null,
        additionalOptionsCheckboxes: {}
      }
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    },
    selectedAccount (account) {
      if (this.val.contractId && !this.contractOptions.find(contract => contract.value === this.val.contractId)) {
        this.val.contractId = null
      }
      if (this.val.cardId && !this.cardOptions.find(card => card.value === this.val.cardId)) {
        this.val.cardId = null
      }
    },
    isStatementOfFeesCheckboxVisible (isVisible) {
      if (!isVisible) {
        this.isStatementOfFeesCheckboxEnabled = false
      }
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.val)
    },
    onPeriodChange () {
      this.onInput()
      this.$emit('on-period-change', this.val.period)
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags']),
    ...mapWritableState(useAccountStatementStore, ['isStatementOfFeesCheckboxEnabled']),
    computedTranslations () {
      return {
        search: this.$pgettext('credit_card_transactions_search', 'Search'),
        period: this.$pgettext('credit_card_transactions_search', 'Period'),
        addMore: this.$pgettext('credit_card_transactions_search', 'Add more'),
        typeHereToSearch: this.$pgettext('credit_card_transactions_search', 'Type here to search'),
        noCardSelected: this.$pgettext('card_transactions_search', 'No card selected'),
        noContractSelected: this.$pgettext('card_transactions_search', 'No contract selected'),
        cardNumber: this.$pgettext('card_transactions_search', 'Card number'),
        contractNumber: this.$pgettext('card_transactions_search', 'Contract number'),
        ...this.translations
      }
    },
    isCreditCardDropdownShown () {
      return this.featureFlags.enableAccountStatementCreditCardAdvancedFilter && this.isAccountFlavor && this.cardOptions.length > 0
    },
    isContractDropdownShown () {
      return this.featureFlags.enableAccountStatementContractNumberAdvancedFilter && this.isAccountFlavor && this.contractOptions.length > 0
    },
    isAccountFlavor () {
      return this.flavor === 'account'
    },
    filteringPeriods () {
      return Object.values(TransactionsPeriodFilter).map((period) => {
        const defaultTranslation = capitalize(period.replace(/_/g, ' ').toLowerCase())

        return {
          value: period,
          text: getTranslation(period, ['TRANSACTION_SEARCH_PERIOD_FILTER'], defaultTranslation)
        }
      })
    },
    filteringAdvancedSearchOptions () {
      return Object.values(TransactionAdvancedSearchOption).map((option) => {
        const defaultTranslation = capitalize(option.split(/(?=[A-Z])/).join(' ').toLowerCase())

        return {
          value: option,
          text: getTranslation(defaultTranslation, ['TRANSACTION_ADVANCED_SEARCH_OPTION'], defaultTranslation)
        }
      })
    },
    advancedFilterEmptyRowModel () {
      return {
        filterValue: '',
        filterFunction: this.filteringAdvancedSearchOptions[0].value
      }
    },
    isAddingNewFiltersDisabled () {
      const hasTooManyFilters = this.val.advancedSearchFilters.length >= this.maxFiltersCount
      const arePreviousFiltersFilled = this.val.advancedSearchFilters.every(filter => (filter?.filterValue || '').trim().length > 0)

      return this.areFilterInputsDisabled || !arePreviousFiltersFilled || hasTooManyFilters
    },
    selectedAccount () {
      return this.accounts.find(account => account.id === this.val.accountId)
    },
    cardOptions () {
      return (this.selectedAccount?.cards || []).map(card => ({
        value: card.id,
        text: [this.cardTypeLabel(card), card.maskedPan].join(' | ')
      }))
    },
    contractOptions () {
      return (this.selectedAccount?.contracts || []).map(contract => ({
        value: contract.id,
        text: contract.contractNumber
      }))
    },
    isCurrentAccountSelected () {
      return this.accounts
        .filter(({ accountTypeName, id }) => accountTypeName === 'Current account' && id === this.val.accountId)
        .length > 0
    },
    isCreditCardSelected () {
      return this.accounts
        .filter(({ accountTypeName, id, cards }) => accountTypeName === 'Client account' && cards.length > 0 && id === this.val.accountId && !this.val.cardId)
        .length > 0
    },
    isStatementOfFeesCheckboxVisible () {
      return (this.isCurrentAccountSelected || this.isCreditCardSelected) && this.featureFlags.enableStatementOfFeesDownload
    },
    statementOfFeesCheckboxData () {
      return {
        label: this.$pgettext('statement_of_fees', 'Statement fees'),
        name: 'STATEMENT_FEES',
        disabled: false,
        tooltip: this.$pgettext('statement_of_fees', 'Statement fees tooltip')
      }
    }
  }
}
</script>
