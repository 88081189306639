import { render, staticRenderFns } from "./DemandDepositEmptyStateCard.vue?vue&type=template&id=4bd4391c&scoped=true"
import script from "./DemandDepositEmptyStateCard.vue?vue&type=script&lang=js"
export * from "./DemandDepositEmptyStateCard.vue?vue&type=script&lang=js"
import style0 from "./DemandDepositEmptyStateCard.vue?vue&type=style&index=0&id=4bd4391c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd4391c",
  null
  
)

export default component.exports