import { namecase } from '@bigbank/dc-common/util/namecase'
import Vue from 'vue'
import isObject from 'lodash/isObject'
const kebabify = s => s.toLowerCase().replace('_', '-')

function syncTranslations (Vue, translations) {
  Vue.$translations = Object.keys(translations).reduce((a, l) => {
    const key = kebabify(l)
    a[key] = handleLang(Vue.$translations[key], translations[l])
    return a
  }, Vue.$translations)
}

function handleLang (previousTranslations, translations) {
  return Object.keys(translations).reduce((a, key) => {
    if (!previousTranslations[key]) {
      a[key] = translations[key]
    } else if (typeof previousTranslations[key] === 'object' && typeof translations[key] === 'object') {
      a[key] = Object.assign({}, previousTranslations[key], translations[key])
    } else if (typeof previousTranslations[key] === 'object') {
      a[key] = Object.assign({}, previousTranslations[key], { '': translations[key] })
    } else {
      a[key] = Object.assign({}, { '': previousTranslations[key] }, translations[key])
    }
    return a
  }, previousTranslations)
}

function translationPicker (obj, language) {
  if (!obj || typeof obj !== 'object') {
    return obj
  }
  const languages = Object.keys(obj)
  return languages.includes(language) ? obj[language] : obj[languages[0]]
}

function getTranslation (key, context, defaultValue, isCompany = false) {
  const translation = (Vue.$translations?.[Vue.config.language] || {})[key]

  if (isObject(translation) && Array.isArray(context) && context.length > 0) {
    const foundTranslation = context
      .map(type => {
        if (isCompany && translation[`CORPORATE_${type}`]) {
          return translation[`CORPORATE_${type}`]
        }

        return translation[type]
      })
      .reduce((chosen, current) => (!chosen ? current : chosen))

    return foundTranslation || defaultValue
  }

  return translation || defaultValue
}

function getTranslatedCountryList (channel, countryList, excludeByCode) {
  let optionList = (countryList || [])
    .map(country => ({
      text: namecase(country.translation || country.countryNameEn),
      value: country.countryCode,
      areaCode: country.areaCode,
      areaCodeAllowed: country.areaCodeAllowed
    }))
    .sort((a, b) => (a.text > b.text) ? 1 : -1)

  if (Array.isArray(excludeByCode)) {
    optionList = optionList.filter((country) => !excludeByCode.includes(country.value))
  }

  const channelCountry = optionList.find(({ value }) => value === channel)

  const shouldMoveChannelCountryAsFirstOption = channelCountry && optionList.length > 10

  if (shouldMoveChannelCountryAsFirstOption) {
    optionList.unshift(channelCountry)
  }

  return optionList
}

export {
  syncTranslations,
  translationPicker,
  getTranslation,
  getTranslatedCountryList
}
