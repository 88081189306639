<template>
  <bb-box thin class="limit-box bg-gray-10">
    <bb-row class="m-b-10">
      <bb-col>
        <p class="f-small" :class="isRestricted ? 'f-color-gray-70' : 'f-color-navy'" style="display: inline-flex;">{{ accountNumber }}</p>
      </bb-col>
      <bb-col col="auto">
        <bb-label v-if="isRestricted" :value="translations.restricted" class="bg-red limit-box__label f-tiniest" size="18" data-testid="limit-box-label" />
        <bb-label v-if="isSeized" :value="translations.seized" class="bg-red limit-box__label f-tiniest" size="18" data-testid="limit-box-label" />
      </bb-col>
    </bb-row>
    <div class="m-y-10">
      <p class="f-tiny f-color-gray-70 f-case-upper">{{ translations.dailyLimit }}</p>
      <p class="f-color-gray f-strong m-b-5">{{ formatNumber(dailyRawLimit) }} {{ this.currency }}</p>
      <p class="f-smallest f-color-gray">{{ translations.currentDailyTransfers }}</p>
      <bb-progress-bar
        :color="isDailyLimitExceeded ? 'gray-70' : 'green'"
        corner="round"
        :value="dailyLimitSpent"
        :max="dailyRawLimit"
        class="m-y-5"
        data-testid="daily-progress-bar"
      />
      <bb-row class="f-smallest f-color-gray f-gotham-book">
        <bb-col>
          <p>{{ translations.dailyLimitSpent }}</p>
        </bb-col>
        <bb-col class="f-align-right">
          <p>{{ translations.dailyLimitLeft }}</p>
        </bb-col>
      </bb-row>
    </div>
    <div class="m-y-10">
      <p class="f-tiny f-color-gray-70 f-case-upper">{{ translations.monthlyLimit }}</p>
      <p class="f-color-gray f-strong m-b-5">{{ formatNumber(monthlyRawLimit) }} {{ this.currency }}</p>
      <p class="f-smallest f-color-gray">{{ translations.currentMonthlyTransfers }}</p>
      <bb-progress-bar
        :color="isMonthlyLimitExceeded ? 'gray-70' : 'green'"
        corner="round"
        :value="monthlyLimitSpent"
        :max="monthlyRawLimit"
        class="m-y-5"
        data-testid="monthly-progress-bar"
      />
      <bb-row class="f-smallest f-color-gray f-gotham-book">
        <bb-col>
          <p>{{ translations.monthlyLimitSpent }}</p>
        </bb-col>
        <bb-col class="f-align-right">
          <p>{{ translations.monthlyLimitLeft }}</p>
        </bb-col>
      </bb-row>
    </div>
  </bb-box>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import { formatMoneyWithCurrency, formatNumberToLocale } from '@/plugins/numformat'
import { BbProgressBar, BbLabel } from '@bigbank/interface-components'

export default {
  name: 'limit-box',
  data () {
    return {
      loading: false
    }
  },
  components: {
    BbProgressBar,
    BbLabel
  },
  props: {
    accountNumber: {
      type: String,
      required: true
    },
    dailyRawLimit: {
      type: Number,
      required: true
    },
    dailyRemainingLimit: {
      type: Number,
      required: true
    },
    monthlyRawLimit: {
      type: Number,
      required: true
    },
    monthlyRemainingLimit: {
      type: Number,
      required: true
    },
    isRestricted: {
      type: Boolean,
      required: true
    },
    isSeized: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale']),
    dailyLimitSpent () {
      return this.dailyRawLimit - this.dailyRemainingLimit
    },
    monthlyLimitSpent () {
      return this.monthlyRawLimit - this.monthlyRemainingLimit
    },
    isDailyLimitExceeded () {
      return this.dailyRemainingLimit <= 0
    },
    isMonthlyLimitExceeded () {
      return this.monthlyRemainingLimit <= 0
    },
    translations () {
      return {
        restricted: this.$pgettext('EDB_limits', 'Restricted'),
        seized: this.$pgettext('EDB_limits', 'Seized'),
        dailyLimit: this.$pgettext('EDB_limits', 'Daily limit'),
        monthlyLimit: this.$pgettext('EDB_limits', 'Monthly limit'),
        dailyLimitSpent: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} spent'), {
          amount: this.formatAmount(this.dailyLimitSpent)
        }),
        dailyLimitLeft: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} left'), {
          amount: this.formatAmount(this.dailyRemainingLimit)
        }),
        currentDailyTransfers: this.$pgettext('EDB_limits', 'Current daily transfers'),
        currentMonthlyTransfers: this.$pgettext('EDB_limits', 'Current monthly transfers'),
        monthlyLimitSpent: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} spent'), {
          amount: this.formatAmount(this.monthlyLimitSpent)
        }),
        monthlyLimitLeft: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} left'), {
          amount: this.formatAmount(this.monthlyRemainingLimit)
        })
      }
    }
  },
  methods: {
    formatNumber (number) {
      return formatNumberToLocale(number, {}, this.locale)
    },
    formatAmount (limit) {
      return formatMoneyWithCurrency(limit, this.currency, this.locale)
    }
  }
}
</script>
<style lang="scss" scoped>
.limit-box__label {
  vertical-align: middle;
}
</style>
